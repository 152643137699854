import React, {useState, useEffect} from 'react';
import { Popover } from 'antd';
import './promoted.scss';

import { CheckBox, Text } from '@ui-kit';
import { ModalImage } from "@features";

const PromotedItem = ({ item = {}, checkedItems = [], setCheckedItems = ()=>{}}) => {
  const [isItemCheck, setItemCheck] = useState();

  const { 
    title = '',
    pathToBrowse = '',
    pathToPreview= '',
    id = 0,
  } = item;

  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;
  const isChecked = checkedItems.filter((item) => item.id === id);
  const content = (
    <Text as="h5">{title}</Text>
  );

  useEffect(() => {
    setItemCheck(!!isChecked.length);
    // eslint-disable-next-line
  }, [checkedItems]);

  const setCheck = (event) => {
    const value = event.target.checked;
    setItemCheck(value);
    
    if(value) {
      setCheckedItems([...checkedItems, item])
    } else {
      const newChecked = checkedItems.filter((item) => item.id !== id)
      setCheckedItems(newChecked)
    };
  };

  return (

    <div className="promoted-ceil d-flex ai-center">
        <div className="promoted-ceil__icon__wrapper">
        <Popover
            overlayStyle={{overflow: 'initial'}}
            overlayClassName="product-title__pop"
            content={content}
            trigger={'hover'}
            arrow={true}
            placement={'topLeft'}
          >
            <>
              <ModalImage
                key={`img-${id}`}
                src={`${PRODUCT_URL}/${pathToBrowse}`}
                backgroundSrc={`${PRODUCT_URL}/${pathToPreview}`}
              />
            </>
          </Popover>
        </div>
        <CheckBox
            className="checkbox__item"
            checked={isItemCheck}
            onChange={setCheck}
        />
    </div>
  );
};

export default PromotedItem;
