import React, { useState, useEffect } from "react";
import "./free.scss";

import { Text, CheckBox } from "@ui-kit";
import { ModalImage } from "@features";

const FreeItem = ({
  item = {},
  checkedItems = [],
  setCheckedItems = () => {},
}) => {
  const [isItemCheck, setItemCheck] = useState();

  const { pathToBrowse = "", pathToPreview = "", title = "", id = 0 } = item;

  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;
  const isChecked = checkedItems.filter((item) => item.id === id);

  useEffect(() => {
    setItemCheck(!!isChecked.length);
    // eslint-disable-next-line
  }, [checkedItems]);

  const setCheck = (event) => {
    const value = event.target.checked;
    setItemCheck(value);

    if (value) {
      setCheckedItems([...checkedItems, item]);
    } else {
      const newChecked = checkedItems.filter((item) => item.id !== id);
      setCheckedItems(newChecked);
    }
  };

  return (
    <div className="admin__table-row">
      <div className="product-name d-flex ai-center">
        <CheckBox
          className="checkbox__item"
          checked={isItemCheck}
          onChange={setCheck}
        />
        <ModalImage
          key={`img-${id}`}
          src={`${PRODUCT_URL}/${pathToBrowse}`}
          backgroundSrc={`${PRODUCT_URL}/${pathToPreview}`}
        />

        <div className="product-title">
          <Text as="h5">{title || ""}</Text>
          <Text as="h6">#{id}</Text>
        </div>
      </div>
    </div>
  );
};

export default FreeItem;
