import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Popover } from 'antd';
import "./filters.scss";

import { Button, Text } from "@ui-kit";
import Filter from "@ui-kit/icons/filter-gray500.svg";
import LayersIcon from "@ui-kit/icons/layers3-gray500.svg";

const Filters = ({
  filters = [],
  filterOrder = "",
  onChangeFilter,
  visibility = false,
  trigger = 'click',
  isArrow = false,
  placement = "bottom",
}) => {
  const [name, setName] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = ["filters__container"];

  if (visibility) {
    classes.push("visibility");
  }
  if (!filters.length) {
    classes.push("empty-filters");
  }

  useEffect(() => {
    const activeValue = filters.find((item) => item.name === name);
    if (!activeValue) {
      setName("");
      return;
    }

    setName(activeValue.name);
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    const searchValue = searchParams.get(filterOrder);
    const activeValue = filters.find((item) => item.value === searchValue);

    if (!activeValue) {
      return setName("");
    }

    setName(activeValue.name);
    // eslint-disable-next-line
  }, [filters]);

  const onChange = (event) => {
    const newValue = event.target.id;
    const newName = event.target.innerText;

    if (newName === name) return;
    setName(newName);

    searchParams.set(filterOrder, newValue);
    setSearchParams(searchParams, { replace: true });
    onChangeFilter();
  };

  const renderItem = (item, index) => {
    const itemClasses = ["filter__item"];

    if (item.name === name) {
      itemClasses.push("active");
    }

    return (
      <Text
        key={`item-${index}`}
        as="li"
        className={itemClasses.join(" ")}
        id={item.value}
        onClick={onChange}
      >
        {item.name}
      </Text>
    );
  };

  const renderButton = () => {
    if (visibility) {
      return (
        <Button
          theme="secondary-gray"
          size="md"
          iconRight={LayersIcon}
        >
          Visibility
        </Button>
      );
    }
    return (
      <Button
        theme="secondary-gray"
        size="md"
        iconLeft={Filter}
      />
    );
  };

  const content = (
    <div className="filter__list-wrapper" >
      <ul className="filter__list">
        {filters.map((item, index) => renderItem(item, index))}
      </ul>
    </div>
  );

  return (
    <div className={classes.join(" ")}>
      <Popover
        content={content}
        trigger={trigger}
        arrow={isArrow}
        placement={placement}
        overlayClassName="filters__popover"
      >
        <>{renderButton()}</>
      </Popover>
    </div>
  );
};

export default Filters;
