import productService from '../api';
import productsService from '../../products/api';
import {
  SET_LOADING,
  SET_PRODUCT,
  SET_EDITED_PRODUCT,
  SET_CART_TO_ACTIVE_PRODUCT,
  SET_CART_TO_SIMILAR_PRODUCTS,
  SET_SIMILAR_IMAGES,
} from './product.constants';
import { modifyProduct } from '../helper';
import { SimilarProductDTO } from '@entities';

export const getProductTC = (params) => {
  return async (dispatch, getState) => {
    try {
      dispatch(isLoadingAC(true));

      const cart = getState()?.userData?.cart;
      const data = await productService.getProduct(params);

      if (window.performance.navigation) {

        if (window.performance.navigation.type !== 1) {

          productService.viewCount();

        }
      }

      if (!data) {
        dispatch(isLoadingAC(false));
        return;
      }

      const product = modifyProduct(data.data);
      const isProductInCart = cart.find((item) => item.id === product.id);

      if (isProductInCart) {
        product.inCart = true;
      }

      const limitedKeywords = product.key_words.slice(0, 3);

      dispatch(getSimilarImagesTC(product.id, limitedKeywords));
      dispatch(setProductAC(product));
      dispatch(isLoadingAC(false));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isLoadingAC(false));
    }
  };
};

const getSimilarImagesTC = (productId, key_words) => {
  return async (dispatch, getState) => {
    const orderBy = 'createdAt';
    const limit = 11; //very often we can get myself = 11-1
    const page = 1;
    const scale = 1920;

    let request = `?orderBy=${orderBy}&limit=${limit}&page=${page}&scale=${scale}`;

    key_words.forEach((key, index) => {
      request += `&searchBy=${key}`;
    });

    const response = await productsService.getSimilarProducts(productId, request);

    if (response.status === 200) {
      const { rows } = response.data;
      const similarProjects = rows
        .map((item) => new SimilarProductDTO(item))
        .filter((item) => item.id !== productId);

      dispatch(setSimilarImagesAC(similarProjects));
      const { userData } = getState();
      return dispatch(
        setCartToSimilarProductsAC({ flag: true, cart: userData.cart })
      );
    }

    return dispatch(setSimilarImagesAC([]));
  };
};

export const getEditedProductTC = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(isLoadingAC(true));

      const { data } = await productService.getEditProduct(id);

      const product = modifyProduct(data);

      dispatch(setEditProductValuesAC(product));
      dispatch(isLoadingAC(false));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isLoadingAC(false));
    }
  };
};

//ACTIONS
export const isLoadingAC = (data) => {
  return {
    type: SET_LOADING,
    payload: data,
  };
};

export const setProductAC = (data) => {
  return {
    type: SET_PRODUCT,
    payload: data,
  };
};

export const setEditProductValuesAC = (data) => {
  return {
    type: SET_EDITED_PRODUCT,
    payload: data,
  };
}

export const setCartToActiveProductAC = (data) => {
  return {
    type: SET_CART_TO_ACTIVE_PRODUCT,
    payload: data,
  };
};

export const setSimilarImagesAC = (data) => {
  return {
    type: SET_SIMILAR_IMAGES,
    payload: data,
  };
};

export const setCartToSimilarProductsAC = (data) => {
  return {
    type: SET_CART_TO_SIMILAR_PRODUCTS,
    payload: data,
  };
};
