import React from 'react';
import './greetings.scss';

import { Icon, Text } from '@ui-kit';
import CheckIcon from '@ui-kit/icons/check-accent500-md.svg';

const GreetingsMessage = () => {
  return (
        <div className="greetings-message">
          <div className="greetings-message__conteiner">
            <div className="greetings-message__content">
              <div className="sub-title">
                There are no projects yet, but...
              </div>
              <div className="title">
                …you can create a project right now!
              </div>
              <div className="check-items">
                  <div className="check-item">
                    <Icon src={CheckIcon} />
                    <Text as="h5">
                      You receive your own secure chat to communicate with our team.
                    </Text>
                  </div>
                  <div className="check-item">
                    <Icon src={CheckIcon} />
                    <Text as="h5">
                      We provide a transparent order execution system for each project stage.
                    </Text>
                  </div>
                  <div className="check-item">
                    <Icon src={CheckIcon} />
                    <Text as="h5">
                      We evaluate an estimate at the beginning of each stage. Once you approve it, 
                      we start working on your illustration.
                    </Text>
                  </div>
              </div>
            </div>
            <div className="greetings-message__image">
              <Icon 
                src="/images/greetings-message-sm.jpg" 
                alt="greetings-message"
                className="greetings__banner"
              />
              <Icon 
                src="/images/banner-greetings-message.png" 
                alt="greetings-message"
                className="greetings__banner-mobile"
              />
            </div>
          </div>
        </div>
    )
};

export default GreetingsMessage;