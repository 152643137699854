import React, {useState, useRef, useEffect} from "react";
import './modal-image.scss';

import { Icon } from '@ui-kit';
import CloseIcon from '@ui-kit/icons/x-white.svg'

const ModalImage = ({className = '', src = '', backgroundSrc = ''}) => {
    const refImage = useRef();
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isScale, setIsScale ] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const classes = ['modal-image__background', ...className.split(' ')]
    const imgClasses = ['modal-image__image'];

    if (isOpen) {
        classes.push('open');
    }

    if (isScale) {
        classes.push('scale');
        imgClasses.push('scale');
    }

    const applyStyle = (imgWidth, imgHeight, boxWidth, boxHeight) => {
        if (imgWidth > imgHeight ) {
            refImage.current.style = "align-items: center;";
            refImage.current.children[0].style = "width: 100%; height: fit-content;";
            return;
        }

        if ( imgWidth < imgHeight && boxWidth > boxHeight) {
            refImage.current.style = "";
            refImage.current.children[0].style = "height: 100%;";
            return;
        }

        if ( imgWidth === imgHeight && boxWidth > boxHeight) {
            refImage.current.style = "";
            refImage.current.children[0].style = "height: 100%;";
            return;
        }

        if ( imgWidth < imgHeight && boxWidth < boxHeight) {
            refImage.current.style = "";
            refImage.current.children[0].style = "";
            return;
        }

        if ( imgWidth === imgHeight && boxWidth < boxHeight) {
            refImage.current.style = "align-items: center;";
            refImage.current.children[0].style = "width: 100%; height: fit-content;";
            return;
        }
    }

    useEffect(() => {
        const imgWidth = refImage.current.children[0].offsetWidth;
        const imgHeight = refImage.current.children[0].offsetHeight;

        const boxWidth = refImage.current.offsetWidth;
        const boxHeight = refImage.current.offsetHeight;

        if (isScale) {
            refImage.current.style = "";
            refImage.current.children[0].style = "";
            return;
        };

        applyStyle(imgWidth, imgHeight, boxWidth, boxHeight);
        // eslint-disable-next-line
    }, [isScale]);

    const handleImageLoad = (event) => {
        if (!refImage.current) return;
        
        const imgWidth = event.target.naturalWidth;
        const imgHeight = event.target.naturalHeight;
        const boxWidth = document.body.clientWidth || 0;
        const boxHeight = document.body.clientHeight || 0;

        applyStyle(imgWidth, imgHeight, boxWidth, boxHeight);
    };

    const onBackgroundClick = (event) => {
        event.stopPropagation();
        closeModal();
    }

    const openModal = () => {
        setIsOpen(true)      
    };

    const closeModal = () => {
        setIsOpen(false)
        setIsScale(false);
    }

    const scaleImage = (event) => {
        event.stopPropagation();
        setIsScale(!isScale);
        setPosition({x: event.clientX, y: event.clientY});
    }

    const moveScroll = (event) => {
        if (!isScale) {
            return
        }

        const factor = 3;
        const diffX = (event.clientX - position.x) * factor;
        const diffY = (event.clientY - position.y) * factor;
        
        refImage.current.scrollLeft += diffX;
        refImage.current.scrollTop += diffY;

        setPosition({x: event.clientX, y: event.clientY});
    }

    return (
        <div className="modal-image">
            <div onClick={openModal} className="modal__children">
                <Icon src={src} className="img-sm" onLoad={handleImageLoad} />
            </div>
            <div className={classes.join(' ')} onClick={onBackgroundClick} >
                <div className={imgClasses.join(' ')} ref={refImage}>
                    <img 
                        src={backgroundSrc || src}
                        onClick={scaleImage}
                        onMouseMoveCapture={moveScroll}
                        alt=""
                    />
                </div>
                <div className="modal__top-panel">
                    <div className="modal__close">
                        <Icon src={CloseIcon} onClick={closeModal} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalImage;