import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./table-products.scss";

import { LoadingAdmin } from "@shared/ui";
import { 
  Text, 
  CheckBox, 
  Button, 
  Icon, 
  Link, 
  IconFeatured, 
  Tooltip,
} from "@ui-kit";
import { PaginationInput, FiltersChecked } from "@widgets";
import {
  getReadyProductsTC,
  getPaginationAmount,
  getIsLoading,
  setSearchPageAC,
  updateProductsSettingsTC,
  getAllAmount,
  getAdminProductsAmountTC,
} from "@entities";
import { ADMIN_PRODUCTS_URL } from "@app/routes";
import ProductsItem from "./productsItem";
//import ItemActions from './itemActions';
import { STATUS_ACTIONS_LIST } from "./item-actions.consts";
import RefreshIcon from "@ui-kit/icons/rotate-cw-gray400.svg";
import DollarOnIcon from "@ui-kit/icons/dollar-gray400.svg";
import DollarOffIcon from "@ui-kit/icons/dollar-off-gray400.svg";
import EyeOnIcon from "@ui-kit/icons/eye-gray400.svg";
import EyeOffIcon from "@ui-kit/icons/eye-off-gray400.svg";
import TrashIcon from "@ui-kit/icons/trash-gray400.svg";
import SortIcon from "@ui-kit/icons/code-gray500.svg";
import SearchIcon from "@ui-kit/icons/search-accent600.svg";
import RocketOnIcon from "@ui-kit/icons/rocket-gray400.svg";
import RocketOffIcon from "@ui-kit/icons/rocket-off-gray400.svg";
import CheckIcon from "@ui-kit/icons/check-accent500-md.svg";
import QuestionIcon from '@ui-kit/icons/help-circle-gray400.svg';

const TableProducts = ({
  products = [],
  setModalDeleteProduct,
  setModalHideProduct,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productsPaginationAmount = useSelector(getPaginationAmount);
  const productsIsLoading = useSelector(getIsLoading);
  const allProductsAmount = useSelector(getAllAmount);

  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  const [field, setField] = useState();
  const [order, setOrder] = useState();
  const [allPage, setAllPage] = useState(1);
  const [isAllProductsChecked, setAllProductsChecked] = useState(false);
  const [forceRender, setForceRender] = useState(true);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const ADD_PRODUCT_URL = process.env.REACT_APP_INSTRUCTION_URL;
  const isEmpty = !+products.length;

  useEffect(() => {
    dispatch(setSearchPageAC("products"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAdminProductsAmountTC());
  }, [dispatch]);

  useEffect(() => {
    if (productsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [productsIsLoading]);

  useEffect(() => {
    if (!checkedProducts.length) {
      setAllProductsChecked(false);
    }
  }, [checkedProducts]);

  useEffect(() => {
    const currentLimit = limit ? limit : 80; 
    const allPages = Math.ceil(+productsPaginationAmount / currentLimit)

    console.log(productsPaginationAmount, limit, currentLimit, allPage, allPages)
    setAllPage(allPages);
  }, [productsPaginationAmount]);

  useEffect(() => {
    const params = getSearchParams();
    const searchByArray = [];

    params.forEach(({ key, value }) => {
      switch (key) {
        case "page": {
          if (!+value || +value < 1) {
            searchParams.set("page", 1);
            setSearchParams(searchParams, { replace: true });
            return setPage(1);
          }

          setPage(value);
          break;
        }

        case "limit": {
          if (!+value || +value < 1) {
            searchParams.set("limit", 80);
            setSearchParams(searchParams, { replace: true });
            setAllPage(Math.ceil(+productsPaginationAmount / 80));

            return setLimit(80);
          }

          const allPages = Math.ceil(+productsPaginationAmount / +value);

          setAllPage(allPages);
          setLimit(+value);

          if (allPages < page) {
            setPage(allPages);
            searchParams.set("page", allPages);
            setSearchParams(searchParams, { replace: true });
            onUpdateData();
          }

          break;
        }

        case "fieldType": {
          // views, likes, createdAt, earned
          if (!value || value === "") {
            searchParams.set("fieldType", "wentPublicAt");
            setSearchParams(searchParams, { replace: true });
            setField("wentPublicAt");
            return;
          }

          setField(value);
          //setPurchasedFieldAC(value);
          break;
        }

        case "orderType": {
          // DESC, ASC
          if (!value || value === "") {
            searchParams.set("orderType", "DESC");
            setSearchParams(searchParams, { replace: true });
            setOrder("DESC");
            return;
          }

          setOrder(value);
          //setPurchasedOrderAC(value);
          break;
        }

        case "searchBy": {
          searchByArray.push(value);
          break;
        }

        default:
          break;
      }
    });

    if (!params.length) {
      searchParams.set("page", 1);
      searchParams.set("limit", 80);
      setSearchParams(searchParams, { replace: true });
    }

    if (searchByArray.length) {
      //const actualSearchValue = searchByArray.join(',');
      //dispatch(setSearchAC(actualSearchValue));
      //dispatch(setResultSearchAC(actualSearchValue));
    }

    return () => {
      //dispatch(setSearchAC(''));
      //dispatch(setResultSearchAC(''));
    };

    // eslint-disable-next-line
  }, [searchParams, productsPaginationAmount]);

  const getMyProducts = () => {
    const params = productsParams();

    const options = {
      ...params,
      limit: params.limit || 80,
      page: params.page || 1,
      /*fieldType: params?.fieldType || 'createdAt',
      orderType: params?.orderType || 'DESC',*/
    };

    dispatch(getReadyProductsTC(options));
  };

  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onChangeValue = (e) => {
    console.log("value", e);
    const value = e.target.value;
    setPage(value);
  };

  const onPageChange = (event) => {
    event.preventDefault();

    const page = +event.target.page.value;

    if (!page) {
      return;
    }

    if (page > allPage) {
      setPage(allPage);
      searchParams.set("page", allPage);
      setSearchParams(searchParams, { replace: true });
      return;
    }

    setPage(page);
    searchParams.set("page", page);
    setSearchParams(searchParams, { replace: true });
    getMyProducts();
  };

  const onClickPrev = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === "page") {
        numberPage = param.value;
      }
    });

    if (!numberPage || numberPage <= 1) {
      return;
    }

    const page = +numberPage - 1;
    searchParams.set("page", page);
    setSearchParams(searchParams, { replace: true });
    setPage(page);
    getMyProducts();
  };

  const onClickNext = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === "page") {
        numberPage = +param.value;
      }
    });

    if (!numberPage || numberPage >= allPage) {
      return;
    }

    const page = +numberPage + 1;
    searchParams.set("page", page);

    setSearchParams(searchParams, { replace: true });
    setPage(page);
    getMyProducts();
  };

  const productsParams = () => {
    const params = window.location.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    return {
      ...params,
      page: params.page || 1,
      limit: params.limit || 80,
      isPublic: params.isPublic || 1,
    };
  };

  const onUpdateData = () => {
    searchParams.delete("isPromoted");
    setSearchParams(searchParams, { replace: true });

    setCheckedProducts([]);

    const params = productsParams();

    return setTimeout(() => dispatch(getReadyProductsTC(params)), 500);
  };

  const onChangeSort = (currentField) => {
    if (currentField !== field) {
      searchParams.set("fieldType", currentField);
      searchParams.set("orderType", "DESC");
      setSearchParams(searchParams, { replace: true });

      setField(currentField);
      setOrder("DESC");
      return getMyProducts();
    }

    const params = getSearchParams();
    let currentOrder = order;

    params.forEach((param) => {
      if (param.key === "orderType") {
        currentOrder = param.value;
      }
    });

    let newOrder = "";
    if (currentOrder === "DESC") {
      newOrder = "ASC";
    } else if (currentOrder === "ASC") {
      newOrder = "DESC";
    } else {
      newOrder = "DESC";
    }

    searchParams.set("orderType", newOrder);
    setSearchParams(searchParams, { replace: true });

    setOrder(newOrder);
    getMyProducts();
  };

  const onChangeStatus = () => {
    getMyProducts();
    /*switch (action) {
      case 'reset': {
        return onUpdateData();
      }
      case 'commercial': {
        searchParams.set('isPromoted', 0);
        setSearchParams(searchParams, {replace: true});

        setCheckedProducts([]);
        return dispatch(getReadyProductsTC({ limit: 80, page: 1, isPublic: 1, isPromoted: 0 }))
      }
      case 'promoted': {
        searchParams.set('isPromoted', 1);
        setSearchParams(searchParams, {replace: true});

        setCheckedProducts([]);
        return dispatch(getReadyProductsTC({ limit: 80, page: 1, isPublic: 1, isPromoted: 1 }))
      }
      default: break
    }*/
  };

  const onAllProductsChecked = (event) => {
    const value = event.target.checked;
    setAllProductsChecked(value);

    if (value) {
      setCheckedProducts([...products]);
    } else {
      setCheckedProducts([]);
    }
  };

  const onPromotedProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    dispatch(
      updateProductsSettingsTC({ ids: prodIds, update: { isPromoted: 1 } })
    );
    onUpdateData();
  };

  const onDoNotPromotedProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    dispatch(
      updateProductsSettingsTC({ ids: prodIds, update: { isPromoted: 0 } })
    );
    onUpdateData();
  };

  const onFreeProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    dispatch(updateProductsSettingsTC({ ids: prodIds, update: { isFree: 1 } }));
    onUpdateData();
  };

  const onDoNotFreeProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    dispatch(updateProductsSettingsTC({ ids: prodIds, update: { isFree: 0 } }));
    onUpdateData();
  };

  const onEditProduct = (id) => {
    navigate(`${ADMIN_PRODUCTS_URL.pathname}/${id}`);
  };

  const onHideProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    setModalHideProduct({
      status: true,
      productsId: prodIds,
      onSubmit: () => setCheckedProducts([]),
    });
  };

  const onActiveProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    dispatch(
      updateProductsSettingsTC({ ids: prodIds, update: { isPublic: 1 } })
    );
    onUpdateData();
  };

  const onDeleteProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    setModalDeleteProduct({
      status: true,
      productsId: prodIds,
      onSubmit: () => setCheckedProducts([]),
    });
  };

  const onSelectAction = (action, id) => {
    switch (action) {
      case "promote": {
        return onPromotedProducts([{ id: id }]);
      }
      case "notPromote": {
        return onDoNotPromotedProducts([{ id: id }]);
      }
      case "free": {
        return onFreeProducts([{ id: id }]);
      }
      case "notFree": {
        return onDoNotFreeProducts([{ id: id }]);
      }
      case "edit": {
        return onEditProduct(id);
      }
      case "hide": {
        return onHideProducts([{ id: id }]);
      }
      case "activate": {
        return onActiveProducts([{ id: id }]);
      }
      case "delete": {
        return onDeleteProducts([{ id: id }]);
      }
      default:
        break;
    }
  };

  const renderTotal = () => {
    const isPublic = searchParams.get("isPublic");

    if (isPublic && isPublic === "1") {
      return (
        <Text as="h6" className="total__wrapper">
          Total {allProductsAmount} products
        </Text>
      );
    }

    const count = products.length ? productsPaginationAmount : 0;

    return (
      <Text as="h6" className="total__wrapper result">
        {count} products hidden
      </Text>
    );
  };

  const renderEyeButton = () => {
    const isPublic = searchParams.get("isPublic");
    if (isPublic && isPublic === "1") {
      return (
        <Button
          theme="link-gray"
          size="lg"
          iconRight={EyeOffIcon}
          className="hide-products"
          onClick={() => onHideProducts(checkedProducts)}
        />
      );
    }

    return (
      <Button
        theme="link-gray"
        size="lg"
        iconRight={EyeOnIcon}
        className="hide-products"
        onClick={() => onActiveProducts(checkedProducts)}
      />
    );
  };

  const renderPromoteButton = () => {
    const isPromotedList = checkedProducts.filter(
      (item) => item.isPromoted === 1
    );

    if (isPromotedList.length) {
      return (
        <Button
          theme="link-gray"
          size="lg"
          iconRight={RocketOffIcon}
          className="hide-products"
          onClick={() => onDoNotPromotedProducts(checkedProducts)}
        />
      );
    }

    return (
      <Button
        theme="link-gray"
        size="lg"
        iconRight={RocketOnIcon}
        className="hide-products"
        onClick={() => onPromotedProducts(checkedProducts)}
      />
    );
  };

  const renderFreeButton = () => {
    const isFreeList = checkedProducts.filter((item) => item.isFree === 1);

    if (isFreeList.length) {
      return (
        <Button
          theme="link-gray"
          size="lg"
          iconRight={DollarOnIcon}
          className="hide-products"
          onClick={() => onDoNotFreeProducts(checkedProducts)}
        />
      );
    }

    return (
      <Button
        theme="link-gray"
        size="lg"
        iconRight={DollarOffIcon}
        className="hide-products"
        onClick={() => onFreeProducts(checkedProducts)}
      />
    );
  };

  const renderProducts = () => {
    const params = productsParams();
    const searchStr = params?.searchBy ? params?.searchBy.replaceAll('+', ' ').trim() : '';

    if (isEmpty && searchStr) {
      return (
        <div className="not-found-message">
          <IconFeatured src={SearchIcon} theme="primary" size="lg" />

          <div className="not-found__content">
            <Text as="h4" className="not-found__title">
              Product not found
            </Text>
            <Text as="h6" className="not-found__subtitle">
              Your search "{searchStr}" did not match any products. Please
              try again or try changing the search term.
            </Text>
          </div>
        </div>
      );
    }

    return products.map((item, index) => (
      <ProductsItem
        key={`product-${index}`}
        item={item}
        checkedItems={checkedProducts}
        setCheckedItems={setCheckedProducts}
        onChange={onSelectAction}
      />
    ));
  };

  const renderContainer = () => {
    const params = productsParams();

    if (isEmpty && !params?.searchBy) {
      return (
        <div className="products__empty">
          <div className="products__header pr">
            <Text as="h5" className="title">
              There are no products yet, but...
            </Text>
            <Text as="h2" className="subtitle">
              …you can add them right now!
            </Text>

            <div className="promote-empty__content">
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">Upload your illustrations to our server</Text>
              </div>
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">Set up your products - you can add or change the title, description and tags</Text>
              </div>
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">Publish your products to our gallery to receive 80%    of every sale of your products</Text>
              </div>
            </div>

            <div className="d-flex pr">
            <Link 
              to={ADD_PRODUCT_URL} 
              theme="secondary" 
              size="md"
              target="_blank" 
              rel="noopener noreferrer"
            >
              Add product
            </Link>
            <Tooltip 
              position="right"
              content={helpContent}
            >
              <Icon src={QuestionIcon} className="ml-6"/>
            </Tooltip>
            </div>


          </div>
          <div className="banner">
            <Icon src="/images/banner-products.png" />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="admin__table">
          <div className="admin__table-title">
            <div className="table-title__actions d-flex">
              <CheckBox
                className="checkbox__item"
                checked={isAllProductsChecked}
                onChange={onAllProductsChecked}
              />
              <Button
                theme="link-gray"
                size="lg"
                iconRight={RefreshIcon}
                className="refresh-products"
                onClick={onUpdateData}
              />
              {!!checkedProducts.length && (
                <>
                  {renderEyeButton()}
                  {renderPromoteButton()}
                  {renderFreeButton()}
                  <Button
                    theme="link-gray"
                    size="lg"
                    iconRight={TrashIcon}
                    className="delete-products"
                    onClick={() => onDeleteProducts(checkedProducts)}
                  />
                </>
              )}
            </div>
            <div>{renderTotal()}</div>
          </div>

          <div className="admin__table-header">
            <Text as="h6" className="product-name">
              Product name
            </Text>
            <Button
              onClick={() => onChangeSort("wentPublicAt")}
              size="md"
              theme="link-gray"
              iconRight={SortIcon}
              className="product-date"
            >
              Release date
            </Button>

            <Button
              onClick={() => onChangeSort("createdAt")}
              size="md"
              theme="link-gray"
              iconRight={SortIcon}
              className="product-date"
            >
              Upload date
            </Button>

            {/*<Button 
                onClick={() => onChangeSort('amount_likes')} 
                size="md" 
                theme="link-gray" 
                iconRight={SortIcon} 
                className="product-favorites"
              >
                Favorites
              </Button>*/}

            <FiltersChecked
              className="product-status"
              filters={STATUS_ACTIONS_LIST}
              onChangeFilter={onChangeStatus}
            >
              <Text as="h6">Status</Text>
            </FiltersChecked>

            <Button
              onClick={() => onChangeSort("amount_views")}
              size="md"
              theme="link-gray"
              iconRight={SortIcon}
              className="product-views"
            >
              Views
            </Button>

            <Button
              onClick={() => onChangeSort("amount_earned")}
              size="md"
              theme="link-gray"
              iconRight={SortIcon}
              className="product-earned"
            >
              Earned
            </Button>

            <Text as="h6" className="product-item__menu">
              {""}
            </Text>
          </div>

          <div className="admin__table-body">
            {renderProducts()}
          </div>
        </div>
        <PaginationInput
          allPage={allPage}
          onSubmit={onPageChange}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          currentPage={page}
          onChangeValue={onChangeValue}
          isDisabled={isEmpty}
          currentLimit={limit}
          viewList={false}
        />
      </>
    );
  };

  return (
    <div className="table-products__container">
      {forceRender ? <LoadingAdmin /> : renderContainer()}
    </div>
  );
};

const helpContent = (
  <>
    <Text as="h6" className="tolltipe__title">
      Get Product Instructions
    </Text>
    <Text as="h6" className="tolltipe__subtitle">
      Download our step-by-step guide on<br/>
      how to upload your work to our server
    </Text>
  </>
);

export default TableProducts;
