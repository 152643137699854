import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "./free.scss";

import FreeItem from "./freeItem";
import { LoadingAdmin } from "@shared/ui";
import { Text, Button, Icon, Link } from "@ui-kit";
import { PaginationInput } from "@widgets";
import {
  updateProductsSettingsTC,
  getReadyProductsTC,
  getCurrentProducts,
  setProductsAC,
  getPaginationAmount,
  getIsLoading,
  setSearchPageAC,
} from "@entities";
import { ADMIN_PRODUCTS_URL } from '@app/routes';
import CheckIcon from "@ui-kit/icons/check-accent500-md.svg";

const FreeProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(getCurrentProducts);
  const productsPaginationAmount = useSelector(getPaginationAmount);
  const productsIsLoading = useSelector(getIsLoading);

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [forceRender, setForceRender] = useState(true);
  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  // eslint-disable-next-line
  const [field, setField] = useState();
  // eslint-disable-next-line
  const [order, setOrder] = useState();
  // eslint-disable-next-line
  const [allPage, setAllPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const isEmpty = !+products.length;

  useEffect(() => {
    if (productsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [productsIsLoading]);

  useEffect(() => {
    dispatch(setSearchPageAC("free"));
    dispatch(getReadyProductsTC({ limit: 80, page: 0, isFree: 1 }));

    return () => {
      dispatch(setProductsAC([]));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = getSearchParams();

    params.forEach(({ key, value }) => {
      switch (key) {
        case "page": {
          if (!+value || +value < 1) {
            searchParams.set("page", 1);
            setSearchParams(searchParams, { replace: true });
            return setPage(1);
          }

          setPage(value);
          break;
        }

        case "limit": {
          if (!+value || +value < 1) {
            searchParams.set("limit", 80);
            setSearchParams(searchParams, { replace: true });
            setAllPage(Math.ceil(+productsPaginationAmount));

            return setLimit(80);
          }

          const allPages = Math.ceil(+productsPaginationAmount / +value);

          setAllPage(allPages);
          setLimit(+value);

          if (allPages < page) {
            setPage(allPages);
            searchParams.set("page", allPages);
            setSearchParams(searchParams, { replace: true });
            onUpdateData();
          }

          break;
        }

        case "fieldType": {
          // views, createdAt
          if (!value || value === "") {
            searchParams.set("fieldType", "createdAt");
            setSearchParams(searchParams, { replace: true });
            setField("createdAt");
            return;
          }

          setField(value);
          break;
        }

        case "orderType": {
          // DESC, ASC
          if (!value || value === "") {
            searchParams.set("orderType", "DESC");
            setSearchParams(searchParams, { replace: true });
            setOrder("DESC");
            return;
          }

          setOrder(value);
          break;
        }

        default:
          break;
      }
    });

    return () => {};

    // eslint-disable-next-line
  }, [searchParams, productsPaginationAmount]);

  const productsParams = () => {
    const params = window.location.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    return {
      ...params,
      page: params.page || 1,
      limit: params.limit || 80,
      isFree: params.isFree || 1,
    };
  };

  const onUpdateData = () => {
    setSearchParams(searchParams, { replace: true });

    setCheckedProducts([]);

    const params = productsParams();

    return setTimeout(() => dispatch(getReadyProductsTC(params)), 500);
  };

  const getMyProducts = () => {
    const params = productsParams();

    const options = {
      ...params,
      limit: params.limit || 80,
      page: params.page || 1,
      /*fieldType: params?.fieldType || 'createdAt',
      orderType: params?.orderType || 'DESC',*/
    };

    dispatch(getReadyProductsTC(options));
  };

  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onCheckedProducts = (checkedProducts) => {
    setCheckedProducts(checkedProducts);
  };

  const onDelete = () => {
    const prodIds = [];
    checkedProducts.map((item) => prodIds.push(item.id));

    dispatch(updateProductsSettingsTC({ ids: prodIds, update: { isFree: 0 } }));

    setCheckedProducts([]);
    onUpdateData();
  };

  const onChangeValue = (e) => {
    console.log("value", e);
    const value = e.target.value;
    setPage(value);
  };

  const onPageChange = (event) => {
    console.log(event);
    event.preventDefault();

    const page = +event.target.page.value;

    if (!page) {
      return;
    }

    if (page > allPage) {
      setPage(allPage);
      searchParams.set("page", allPage);
      setSearchParams(searchParams, { replace: true });
      return;
    }

    setPage(page);
    searchParams.set("page", page);
    setSearchParams(searchParams, { replace: true });
    getMyProducts();
  };

  const onClickPrev = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === "page") {
        numberPage = param.value;
      }
    });

    if (!numberPage || numberPage <= 1) {
      return;
    }

    const page = +numberPage - 1;
    searchParams.set("page", page);
    setSearchParams(searchParams, { replace: true });
    setPage(page);
    getMyProducts();
  };

  const onClickNext = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === "page") {
        numberPage = +param.value;
      }
    });

    if (!numberPage || numberPage >= allPage) {
      return;
    }

    const page = +numberPage + 1;
    searchParams.set("page", page);

    setSearchParams(searchParams, { replace: true });
    setPage(page);
    getMyProducts();
  };

  const renderButton = () => {
    if (checkedProducts.length) {
      return `Selected to remove ${checkedProducts.length} out of ${productsPaginationAmount}`;
    }

    return `Select to remove`;
  };

  const renderFree = () => {
    if (forceRender) {
      return <LoadingAdmin />;
    }

    if (isEmpty) {
      return (
        <div className="free__empty">
          <div className="free__header pr">
            <Text as="h2" className="title">
              Free products
            </Text>
            <Text as="h5" className="subtitle">
              Featuring selected free illustrations, you can increase your
              visibility and increase your income.
            </Text>

            <div className="promote-empty__content">
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">You can always free up to 30 products</Text>
              </div>
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">You can always replace illustrations</Text>
              </div>
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">You can always cancel free</Text>
              </div>
            </div>
            <Link to={ADMIN_PRODUCTS_URL} theme="secondary" size="md">
              Start selection
            </Link>
          </div>
          <div className="banner">
            <Icon src="/images/banner-promote.png" />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="free__header">
          <Text as="h4" className="title">
            Free products
          </Text>
          <Text as="h6" className="subtitle">
            Selected products can be downloaded free of charge by all authorized
            users. These products are provided with an extended commercial
            license.
          </Text>
          <Button
            theme="secondary-gray-danger"
            size="md"
            isDisabled={!checkedProducts.length}
            onClick={onDelete}
          >
            {renderButton()}
          </Button>
        </div>

        <div className="admin__table">
          <div className="free__table-body">
            {products.map((item, index) => {
              return (
                <FreeItem
                  key={`product-${index}`}
                  item={item}
                  checkedItems={checkedProducts}
                  setCheckedItems={onCheckedProducts}
                />
              );
            })}
          </div>
        </div>

        <PaginationInput
          allPage={allPage}
          onSubmit={onPageChange}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          currentPage={page}
          onChangeValue={onChangeValue}
          isDisabled={isEmpty}
          currentLimit={limit}
          viewList={false}
        />
      </>
    );
  };

  return (
    <div className="free__container">
      {renderFree()}
    </div>
  );
};

export default FreeProducts;
