import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "./promoted.scss";

import PromotedItem from "./promotedItem";
import { LoadingAdmin } from "@shared/ui";
import { Text, Button, Icon, Link } from "@ui-kit";
import {
  updateProductsSettingsTC,
  getReadyProductsTC,
  getCurrentProducts,
  setProductsAC,
  getPaginationAmount,
  getIsLoading,
  setSearchPageAC,
} from "@entities";
import { ADMIN_PRODUCTS_URL } from '@app/routes';
import CheckIcon from "@ui-kit/icons/check-accent500-md.svg";

const PromotedProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(getCurrentProducts);
  const productsPaginationAmount = useSelector(getPaginationAmount);
  const productsIsLoading = useSelector(getIsLoading);

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [forceRender, setForceRender] = useState(true);
  const [page, setPage] = useState();
  // eslint-disable-next-line
  const [field, setField] = useState();
  // eslint-disable-next-line
  const [order, setOrder] = useState();
  // eslint-disable-next-line
  const [allPage, setAllPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (productsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [productsIsLoading]);

  useEffect(() => {
    dispatch(setSearchPageAC("promote"));
    dispatch(getReadyProductsTC({ limit: 100, page: 0, isPromoted: 1 }));

    return () => {
      dispatch(setProductsAC([]));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = getSearchParams();

    params.forEach(({ key, value }) => {
      switch (key) {
        case "page": {
          if (!+value || +value !== 1) {
            searchParams.set("page", 1);
            setSearchParams(searchParams, { replace: true });
            return setPage(1);
          }

          setPage(value);
          break;
        }

        case "limit": {
          if (!+value || +value !== 100) {
            searchParams.set("limit", 100);
            setSearchParams(searchParams, { replace: true });
            setAllPage(Math.ceil(+productsPaginationAmount));

            return;
          }

          const allPages = Math.ceil(+productsPaginationAmount / +value);

          setAllPage(allPages);

          if (allPages < page) {
            setPage(allPages);
            searchParams.set("page", allPages);
            setSearchParams(searchParams, { replace: true });
          }

          break;
        }

        case "fieldType": {
          // views, createdAt
          if (!value || value === "") {
            searchParams.set("fieldType", "createdAt");
            setSearchParams(searchParams, { replace: true });
            setField("createdAt");
            return;
          }

          setField(value);
          break;
        }

        case "orderType": {
          // DESC, ASC
          if (!value || value === "") {
            searchParams.set("orderType", "DESC");
            setSearchParams(searchParams, { replace: true });
            setOrder("DESC");
            return;
          }

          setOrder(value);
          break;
        }

        default:
          break;
      }
    });

    return () => {};

    // eslint-disable-next-line
  }, [searchParams, productsPaginationAmount]);

  const onUpdateData = () => {
    const limit = searchParams.get("limit") || 100;
    const page = searchParams.get("page") || 0;

    return setTimeout(
      () =>
        dispatch(
          getReadyProductsTC({
            limit: limit,
            page: page,
            isPromoted: 1,
          })
        ),
      500
    );
  };

  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onCheckedProducts = (checkedProducts) => {
    setCheckedProducts(checkedProducts);
  };

  const onDelete = () => {
    const prodIds = [];
    checkedProducts.map((item) => prodIds.push(item.id));

    dispatch(
      updateProductsSettingsTC({ ids: prodIds, update: { isPromoted: 0 } })
    );

    setCheckedProducts([]);
    onUpdateData();
  };

  const renderButton = () => {
    if (checkedProducts.length) {
      return `Selected to remove ${checkedProducts.length} out of ${products.length}`;
    }

    return `Select to remove`;
  };

  const renderPromote = () => {
    if (forceRender) {
      return <LoadingAdmin />;
    }

    if (!products.length) {
      return (
        <div className="promoted__empty">
          <div className="promoted__header pr">
            <Text as="h2" className="title">
              Promote your products!
            </Text>
            <Text as="h5" className="subtitle">
              You now have a promotional opportunity for your illustrations.
              Featuring selected products at the top of the gallery each day,
              you can boost recognition and increase revenue. Simply select the
              illustrations you want to showcase - they will get the most views
              every day.
            </Text>

            <div className="promote-empty__content">
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">
                  Add up to 80 products - the entire first page of the gallery
                  can consist of promoted products
                </Text>
              </div>
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">
                  You can promote any illustrations - from your favorites to the
                  most profitable ones
                </Text>
              </div>
              <div className="list-content d-flex">
                <Icon src={CheckIcon} />
                <Text as="h5">
                  You can always remove and replace promoted products
                </Text>
              </div>
            </div>
            <Link to={ADMIN_PRODUCTS_URL} theme="secondary" size="md" >Start selection</Link>
          </div>

          <div className="banner">
            <Icon src="/images/banner-promote.png" />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="promoted__header">
          <Text as="h4" className="title">
            Promoted products
          </Text>
          <Text as="h6" className="subtitle">
            The selected products displayed in the first rows on the Gallery
            page. However, this will not affect search results.
          </Text>
          <Button
            theme="secondary-gray-danger"
            size="md"
            isDisabled={!checkedProducts.length}
            onClick={onDelete}
          >
            {renderButton()}
          </Button>
        </div>

        <div className="promoted__table-body">
          {products.map((item, index) => {
            return (
              <PromotedItem
                key={`product-${index}`}
                item={item}
                checkedItems={checkedProducts}
                setCheckedItems={onCheckedProducts}
              />
            );
          })}
        </div>
      </>
    );
  };

  return <div className="promoted__container">{renderPromote()}</div>;
};

export default PromotedProducts;
