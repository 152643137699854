import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './links.scss';
import { Icon } from "@ui-kit";

/*
 *<Link to="/home" theme="secondary" size="sm" disabled={true}>
 */

const Link = (props) => { 
  let { 
    theme = 'primary',
    size = 'xl',
    disabled = false,
    to = '', 
    children, 
    icon, 
    className, 
    ...options 
  } = props;

  const defaultDisabled = disabled ? 'disabled' : '';

  const rootClasses = ['link', className, theme, size, defaultDisabled];

  const buildMultiUrl = (url) => {
    let returnedUrl = url.pathname;
    if (url.hasOwnProperty('search')) {
      returnedUrl+=url.search;
    }

    return returnedUrl.concat(url.hash);
  }

  if (to?.hash) {
    return (
      <HashLink
        to={buildMultiUrl(to)}
        {...options} 
        className={rootClasses.join(' ')}
      >
        {icon && <Icon src={icon} alt="icon"/>}
        {children}
      </HashLink>
    )
  }

  return (
    <RouterLink 
      to={to} 
      {...options} 
      className={rootClasses.join(' ')}
    >
      {icon && <Icon src={icon} alt="icon"/>}
      {children}
    </RouterLink>
  );
};

export default Link;


/*
<Link size-"lg" to="/" theme="text" className="arrow-right">
  Fill out the brief
</Link>
*/